import React from 'react';
import { IonContent, IonItem, IonItemGroup, IonLabel, IonPage, useIonAlert } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { Modal } from 'antd';
import { func, types } from 'utils';
import { Header, TabsOffset } from 'components';
import { _store, _update } from '_Store';

import './_User.scss';

// user forms
import Login from '../Auth/Login';
import FormEmail from './FormEmail';
import FormPhone from './FormPhone';
import FormProfile from './FormProfile';
import FormPassword from './FormPassword';

interface Props extends RouteComponentProps, types.StateProps { };
const User: React.FC<Props> = props => {
    const [presentAlert] = useIonAlert();

    const { __auth: { user, isLoggedIn }, __data: { cartDefault, countries, active_venue, location } } = _store.useState();

    const [edit, setEdit] = React.useState('');

    React.useEffect(() => {
        if (func.getStorage('defaultPWD')) {
            setEdit(`password`);
        }
    }, []);

    const lists = [
        { key: 'orders', label: `<div>My Orders</div>`, },
        { key: 'favs', label: `<div>My Favorites</div>`, },
        { key: 'break', },
        {

            key: 'profile',
            label: `
                        <div>Edit Profile Details
                            <div class="text-muted small mt-2 text-capitalize">
                            ${user.first_name} ${user.last_name} • ${user.gender || 'Gender'} • ${user.birthdate !== '1870-01-01' ? moment(user.birthdate).format('LL') : 'Birth date'}
                            </div>
                        </div>
                    `,
        },
        { key: 'phone', label: `<div>Edit Phone Number <div class="text-muted small mt-2">${user.phone_number}</div></div>`, },
        { key: 'email', label: `<div>Edit Email Address <div class="text-muted small mt-2">${user.email || 'N/A'}</div></div>`, },
        { key: 'password', label: `<div>Change Password <div class="text-muted small mt-2">• • • • • • • •</div></div>`, },
        { key: 'break', },
        { key: 'logout', label: `<div>Log Out</div>`, },
    ]

    const gotoPage = (key: string) => {
        switch (key) {
            default:
                setEdit(`${key}`);
                break;

            case 'favs':
                props.history.push('/user/favourites');
                break;

            case 'orders':
                props.history.push('/user/orders');
                break;

            case 'logout':
                presentAlert({
                    header: 'Oops!',
                    message: 'Are you sure you want to logout?',
                    buttons: [
                        { text: 'Cancel' },
                        {
                            text: 'Yes, Log out', handler: () => {
                                _update.data('item', {});
                                _update.data('cart', cartDefault);
                                _update.data('order', {});
                                _update.data('active_venue', {});
                                _update.data('delivery_fees', []);
                                
                                _update.auth('user', {});
                                _update.auth('token', {});
                                func.init();
                                // func.redirect('/');
                            }
                        }
                    ],
                });
                break;
        }
    }

    return (
        <React.Fragment>
            {isLoggedIn ? (
                <IonPage id="User">
                    <Header {...props} />

                    <IonContent fullscreen>
                        <div className="p-3 text-center text-white fw-600 title m-4">
                            <span className="secondary">Hello,</span> <span className="">{user.first_name}</span>
                        </div>

                        <div className="px-3">
                            <div>&nbsp;</div>
                            <IonItemGroup className="mt-0">
                                {lists.map((item: any, i: number) => (
                                    item.key === 'break' ? (
                                        <div key={`${i}-${item.key}`} className="divider my-3" />
                                    ) : (
                                        <IonItem key={item.key} button lines="none" mode="md" className="animate__animated animate__fadeIn" onClick={() => gotoPage(item.key)}>
                                            <IonLabel>
                                                <div className="my-1" dangerouslySetInnerHTML={{ __html: item.label }} />
                                            </IonLabel>
                                        </IonItem>
                                    )
                                ))}
                            </IonItemGroup>

                            <p>&nbsp;</p>
                            {countries.length > 0 && (
                                <div className="text-center text-muted small">
                                    {active_venue.name} <br />
                                    {func.distanceMatrix(location.address, active_venue.address)}
                                </div>
                            )}
                            <div
                                className="text-center muted small mt-2"
                                onClick={() => {
                                    caches.keys().then(keys => {
                                        Promise.all(keys.map(key => caches.delete(key))).then(() => {
                                            func.redirect('./');
                                        });
                                    });
                                }}
                            >Chicken Republic v{func.app.version}</div>
                            <p>&nbsp;</p>
                        </div>

                        <Modal open={edit ? true : false} width="90vw" centered title={null} footer={null} destroyOnClose={true} maskClosable={false} onCancel={() => setEdit('')}>
                            {edit === 'profile' && <FormProfile {...props} onSuccess={() => setEdit('')} />}
                            {edit === 'phone' && <FormPhone {...props} onSuccess={() => setEdit('')} />}
                            {edit === 'email' && <FormEmail {...props} onSuccess={() => setEdit('')} />}
                            {edit === 'password' && <FormPassword {...props} onSuccess={() => setEdit('')} />}
                        </Modal>
                        <TabsOffset />
                    </IonContent>
                </IonPage>
            ) : (
                <Login {...props} />
            )}
        </React.Fragment>
    );
};

export default User;
