import { isPlatform } from '@ionic/react';
import moment from 'moment-timezone';
import latinize from 'latinize';
import { types } from 'utils';
import { Browser } from '@capacitor/browser';


export const config = {
    env: 'live',
    apiURL: {
        'live': 'https://chr-api.tomati.app/',
        'local': 'http://tomati.local/',
        'staging': 'https://cr-api.tomati.app/',
    },
    version: {
        apk: '1.0.0',
        ios: '1.0.0',
    },
    platform: 'apk',
}

export const init = () => {
    config['platform'] = isPlatform('ios') ? 'ios' : 'apk';
}

export const app = {
    version: config.version[config.platform as keyof typeof config.version] || '1.0.0',
    dbpref: 'cr_aarin_',
}

export const dates = {
    yr: moment().format('YYYY'),
    dt: moment().format('YYYY-MM-DD'),
}

export const isMobile = !!(isPlatform('capacitor') || isPlatform('cordova'));


// ::: storage
export const setStorage = (key: string, value: any) => {
    if (key) {
        localStorage.setItem(app.dbpref + key, value);
    }
}
export const getStorage = (key: string) => {
    const value = localStorage.getItem(app.dbpref + key);
    return value || '';
}
export const setStorageJson = (key: string, value: any, onOK: any = null) => {
    if (key && value) {
        localStorage.setItem(app.dbpref + key, JSON.stringify(value));
        onOK && onOK();
    }
}
export const getStorageJson = (key: string) => {
    if (key) {
        const value = localStorage.getItem(app.dbpref + key) || '';
        return isJson(value) ? JSON.parse(value) : '';
    }
}
export const delStorage = (key: string) => {
    if (key) {
        localStorage.removeItem(app.dbpref + key);
    }
}


export const inArray = (needle: any, haystack: any): boolean => {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
    }
    return false;
}
export const mergeObj = (obj: any, src: any) => {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
export const getFileExtension = (filename: string) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
}

export const redirect = (to: any) => {
    // return false;
    window.location = to;
}

export const generateOptions = (length: number, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
}

export const numberFormat = (number: number, decimal: number = 2) => {
    // eslint-disable-next-line no-useless-escape
    number = +(number || 0).toString().replace(/[^0-9\./]/g, '');
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: decimal }).format(number);
}

export const seourl = (x: string) => {
    if (x) {
        x = latinize(x)
            .replace(/[`~!@#$%^&*()_\-+=\\[\]{};:'"\\|\\/,.<>?\s]/g, ' ')
            .toLowerCase()
            .replace(/^\s+|\s+$/gm, '')
            .replace(/\s+/g, '-');
        return x;
    }
    return x;
}

export const isJson = (str: any) => {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

export const togglePasswordShow = (e: any) => {
    var x = document.getElementById(e) as HTMLInputElement;
    if (x) {
        if (x.type === 'password') {
            x.setAttribute('type', 'text');
        } else {
            x.setAttribute('type', 'password');
        }
    }
}

export const cartTotal = (items: any) => {
    let total = 0;
    items.forEach((item: any) => {
        let price = item.price;
        Object.keys(item.product_options).forEach(po => {
            if (item.product_options[po].price > 0) {
                price = item.product_options[po].price;
            }
        });
        let paidPrice = (item.paid_sides || []).length && item.paid_sides.reduce(function (sum: number, ps: any) {
            return sum + ps.price;
        }, 0);
        total = total + ((price + paidPrice) * (item.quantity || 1));
    });
    return total;
}

export const distanceMatrix = (pointA: any = {}, pointB: any = {}, onlyNumber: boolean = false) => {
    var lat1 = pointA.lat;
    var lng1 = pointA.lng;
    var lat2 = pointB.lat;
    var lng2 = pointB.lng;
    if ((lat1 === lat2 && lng1 === lng2) || (!lat1 || !lat2 || !lng1 || !lng2)) {
        return onlyNumber ? 0 : '--km away';
    } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lng1 - lng2;
        var radtheta = (Math.PI * theta) / 180;
        var dist: number =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = +(((dist * 180) / Math.PI) * 60 * 1.1515 * 1.609344).toFixed(3);
        return onlyNumber ? dist : `${dist}km away`;
    }
}

export const prepTime = (preparation_time: string) => {
    if (preparation_time === '00:00:00') {
        return 'Ready immediately';
    }
    return `Ready in ${`${moment.duration(preparation_time).asMinutes()} minutes`}`;
}

export const ucWords = (string: string) => {
    return string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
}

export const chunk = (inputArray: any, chunkSize: number) => {
    return inputArray.reduce((resultArray: any, item: any, index: number) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray
    }, []);
}

export const getTimeRemaining = (endtime: string) => {
    const total = Date.parse(endtime) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours: hours.toString().length > 1 ? hours : `0${hours}`,
        minutes: minutes.toString().length > 1 ? minutes : `0${minutes}`,
        seconds: seconds.toString().length > 1 ? seconds : `0${seconds}`,
    };
}


export const isOpen = (venue: types.Venue) => {
    var open = false;
    if (venue?.address?.time_zone) {
        var day = moment().format('dddd').toLowerCase();
        var today = moment().format('YYYY-MM-DD');
        var schedule = (venue?.schedule || []).find((bh: any) => bh.day === day) || {} as types.Schedule;
        var isOnline = typeof venue.preferences.online !== 'undefined' ? venue.preferences.online : true;
        open = schedule.available ? isOnline && moment().tz(venue?.address.time_zone).isBetween(`${today} ${schedule.opening}`, `${today} ${schedule.closing}`) : false;
    }
    return open;
}

export const browser = async (link: string) => {
    if (isMobile) {
        await Browser.open({ url: link });
    } else {
        window.open(link, '_system');
    }
}

export const versionBreak = (v: string) => {
    const vv = v ? Number(v.split('.').join('')) : 0;
    return +(vv.toString().length < 4 ? `${vv}0` : vv);
}

export const copyObject = (o: any) => JSON.parse(JSON.stringify(o));

export const isLocal = window.location.host.includes('localhost');
export const isStaging = window.location.host.includes('cr-mobile');
export const isStagingOrLocal = isLocal || isStaging;


(function () {
    if (window.location.host.includes('localhost')) {
        config['env'] = 'local';
    } else if (window.location.host.includes('cr-mobile')) {
        config['env'] = 'staging';
    } else {
        config['env'] = 'live';
    }
})();