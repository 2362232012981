import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { cartFx } from 'utils';
import { _store } from '_Store';

interface StateProps {
    tabs: {
        path: string;
        icon?: string;
        icon2?: string;
        component?: any;
    }[];
}
interface Props extends StateProps { };
const Tabs: React.FC<Props> = props => {

    const { __data: { cart, location } } = _store.useState(s => s);

    const history = useHistory();
    const lokation = useLocation();

    const [active, setActive] = React.useState(lokation.pathname.split('/')[1]);

    React.useEffect(() => {
        setActive(lokation.pathname.split('/')[1]);
    }, [lokation.pathname]);

    const navigate = (tab: typeof props.tabs[0]) => {
        history.replace(tab.path);
        window.scroll(0, 0);
    }

    return (
        <React.Fragment>
            {location.address.iso && props.tabs.map(t => t.path).includes(lokation.pathname) && (
                <div id="Tabs" className="d-flex justify-content-between">
                    {props.tabs.map(tab => (
                        <div key={tab.path} onClick={() => navigate(tab)} style={{ position: 'relative' }}>
                            <img src={active === tab.path.split('/')[1] ? tab.icon2 : tab.icon} alt={tab.path} />
                            {cart.items.length > 0 && tab.path === '/cart' && (
                                <div className="cart_count">{cartFx.totalItems(cart.items)}</div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

export default Tabs;


export const Offset = () => <div style={{ marginTop: document.getElementById('Tabs')?.offsetHeight }} />;