import React from 'react';
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Empty, Filter, Header, Item, TabsOffset } from 'components';
import { axius, func, types } from 'utils';
import { _store } from '_Store';


let refresher: any = null;
interface Params { id: string };
interface Props extends RouteComponentProps, types.StateProps { };
const Category: React.FC<Props> = props => {

    const { __data: { categories, active_venue, location } } = _store.useState();

    const [step, setStep] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [chunks, setChunks] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const path = props.history.location.pathname.split('/')[2]?.split('.') || {};
    const params: Params = { id: path[1], };

    React.useEffect(() => {
        getData();
        // window['$'](`#carousel-category-filterss`).owlCarousel(owlCarouselOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const getNext = () => {
        setStep(step + 1);
        setData(data.concat(chunks[step + 1]));
    }

    const getData = () => {
        setLoading(true);
        window.scroll(0, 0);
        axius.post('search', {
            city: location.address.city,
            limit: 50,
            venues: [active_venue.uuid],
            results_type: ['items'],
            delivery_options: [location.option],
            venue_categories: [categories.find((ctg: types.Category) => ctg.id === +params.id)?.uuid],
        }).then(res => {
            if (res.status === 200) {
                var c = func.chunk(
                    res.items.map((item: types.Item) => {
                        return {
                            ...item,
                            distance: +func.distanceMatrix(
                                { lat: location?.address?.lat, lng: location?.address?.lng },
                                { lat: active_venue.address.lat, lng: active_venue.address.lng },
                                true
                            ),
                        };
                    })
                        .sort((a: types.Item, b: types.Item) => (a.distance > b.distance) ? 1 : -1).filter((prod: types.Item) => prod.status === 'published')
                    , 12
                );
                setChunks(c || [[]]);
                setData(c[0] || []);
            }
            setLoading(false);
            refresher && refresher.detail.complete();
        });
    }

    const ctg = (categories.find(ctg => ctg.id === +params.id) || {}) as types.Category;

    return (
        <IonPage id="Category">
            <Header {...props} showSearch={true} showSegments={false} />
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={(e) => {
                    refresher = e;
                    getData();
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <Filter {...props} />
                <div className="divider" />
                {!loading && chunks.flat().length === 0 && (
                    <Empty
                        style={{}}
                        text={
                            <div>
                                {chunks.flat().length ? func.numberFormat(chunks.flat().length) : 'No'} item{chunks.flat().length > 1 ? 's' : ''} found&nbsp;
                                {ctg.id && <span>in <span className="primary fw-600">{ctg.name}</span></span>}
                            </div>
                        }
                    />
                )}

                <div className="p-3">
                    {loading && [0, 1, 2, 3, 4].map(i => (
                        <div key={i} className="skeleton mb-2" style={{ borderRadius: 7, height: 160 }} />
                    ))}

                    {!loading && data.length > 0 && data.map((item: types.Item) => (
                        item.status === 'published' && (
                            <div key={item.id} className="mb-4s">
                                <Item {...props} item={item} layout="list" />
                            </div>
                        )
                    ))}

                    {(chunks.flat()).length > data.length && (
                        <div className="text-center pointer small" onClick={() => getNext()}>Load more</div>
                    )}
                    <p>&nbsp;</p>
                </div>
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Category;
