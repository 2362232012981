import React from 'react';
import { IonContent, IonPage, useIonAlert } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Form, Button, InputGroup } from 'react-bootstrap';
import queryString from 'query-string';
import { axius, func, images, mapFx, types } from 'utils';
import { CustomRadio, GoBack, Header, Loading, TabsOffset } from 'components';
import md5 from 'js-md5';
import { _store, _update } from '_Store';

interface StateProps {
    title?: string;
    verify?: boolean;
    onFinish?: any;
    asComponent?: boolean;
}
interface Props extends RouteComponentProps, types.StateProps, StateProps { };
const Location: React.FC<Props> = props => {

    const { asComponent } = props;
    const { __data: { location, options }, } = _store.useState();

    let v = location;
    let zoom = 17;
    const { maps } = (window as any).google || {};

    if (!maps) {
        func.redirect('/');
    }

    const [presentAlert] = useIonAlert();

    const [step, setStep] = React.useState(1);
    const [geolocating, setGeolocating] = React.useState(false);

    var marker: any = null;

    React.useEffect(() => {
        if (props.history.location.search) {
            const qs = queryString.parse(props.history.location.search);
            const qwert = JSON.parse(atob(qs.ordercr as string));

            axius.post('search', {
                city: qwert.store.address.city,
                range: 30,
                limit: 1,
                dataKey: 'venues',
                latitude: qwert.store.address.lat,
                longitude: qwert.store.address.lng,
                results_type: ['venues'],
                delivery_optionsX: [location.option],
            }).then(res => {
                if (res.status === 200) {
                    _update.data('location', { option: 'delivery', radius: 30, address: qwert.store.address });
                    _update.data('active_venue', res.venues[0]);
                }
                func.redirect('/');
            });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history.location.search]);

    React.useEffect(() => {
        if (maps && document.getElementById('map')) {
            var map = new maps.Map(document.getElementById('map'), {
                center: { lat: v.address.lat || 9.558322169079608, lng: v.address.lng || 7.916620326712789 },
                // styles: mapStyle,
                zoom: v.address.zoom || 7,
                draggable: true,
                clickableIcons: true,
                disableAutoPan: true,
                disableDefaultUI: true,
                mapTypeId: maps.MapTypeId.ROADMAP,
            });
            if (v.address.lat) {
                setMarker(map, v.address);
            }
        }
        const Autocomplete = new maps.places.Autocomplete(document.getElementById('setMyLocation'), {
            componentRestrictions: { country: func.config.env === 'live' ? 'ng' : null }
        });
        Autocomplete.addListener('place_changed', async () => {
            const plc = Autocomplete.getPlace();
            const address = mapFx.addressComponents(plc);
            if (md5(JSON.stringify(address)) !== md5(JSON.stringify(v.address))) {
                _update.data('location', { ...v, address: { ...address, zoom, } });
            }
            // setMarker(map, mapFx.addressComponents(plc));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // React.useEffect(() => {
    //     if (location.address.name) {
    //         // document.getElementById('setMyLocation')?.value = '';
    //         // document.getElementsByClassName('addressName')[0].html = location.address.name;
    //     }
    // }, [location]);

    const getMyCurrentLocation = () => {
        mapFx.myCurrentLocation().then(address => {
            address && _update.data('location', { ...v, address: { ...address, zoom, } });
        });
    }

    const setMarker = (map: any, address: any) => {
        // console.log(address);
        map.setCenter({ lat: +address.lat, lng: +address.lng });
        map.setZoom(17);
        var pointB = new maps.LatLng(address.lat, address.lng);
        marker && marker.setMap && marker.setMap(null);
        marker = new maps.Marker({
            title: address.name,
            position: pointB,
            draggable: true,
            animation: maps.Animation.DROP,
        });
        marker.setMap(map);
        if (md5(JSON.stringify(address)) !== md5(JSON.stringify(v.address))) {
            _update.data('location', { ...v, address: { ...address, zoom, } });
        }

        maps.event.addListener(marker, 'dragend', function (event: any) {
            var address = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                zoom,
            };
            mapFx.geocode(address.lat, address.lng).then(geoc => {
                setGeolocating(true);
                if (geoc.status === 200 && geoc.data.status === 'OK') {
                    setGeolocating(false);
                    _update.data('location', { ...v, address: { ...address, ...mapFx.addressComponents(geoc.data.results[0]) } });
                }
            });
        });
    }

    const submit = () => {
        if (step === 1) {
            func.delStorage('after_login');
            props.onFinish ? props.onFinish() : func.redirect('/');
        } else {
            if (v.address.lat && v.address.lng) {
                setStep(step + 1);
            } else {
                presentAlert(`Please choose a location`);
            }
        }
    }

    const hasHeader = !!(!asComponent && props.location.pathname !== '/location');

    if (props.history.location.search) {
        return <Loading />;
    }

    return (
        <IonPage id="Location">
            {hasHeader && (
                <Header {...props} />
            )}

            <IonContent fullscreen={false}>
                <div id="Location" className={hasHeader ? 'content c-small p-4' : 'p-4'}>
                    {step > 1 && (
                        <div className="mb-4">
                            <GoBack {...props} onClick={() => { setStep(step - 1); }} />
                        </div>
                    )}

                    {/* ::: step 1 */}
                    <div className={`animate__animated animate__fadeIn animate__faster mb-4 ${step !== 1 ? 'hide' : ''}`}>
                        <h4 className="fs-16 fw-600 primary d-flex align-items-center">
                            <img src={images.Location2} alt="Your location - Chicken Republic" style={{ height: 20 }} />
                            <div className="ml-2">Where Are You?</div>
                        </h4>
                        <p className="mb-3">We use your location to show nearby outlets available for pick up or delivery.</p>
                        {v.address.name && (
                            <div className="mb-3 mt-5s primary">
                                <div className="text-muted mb-2">Current Location</div>
                                {/* <img src={images.Location2} alt="Your location - Chicken Republic" className="img-fluid mr-1" style={{ height: 18 }} /> */}
                                <span className="addressName">{v.address.name}</span>
                            </div>
                        )}
                        <Form.Group className="w-100">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text className="border-right-0 bg-white">
                                        {geolocating ? <i className="fa fa-spin fa-circle-notch primary" /> : <img src={images.Location2} alt="Your location - Chicken Republic" style={{ height: 15 }} />}
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control className="border-left-0" type="text" name="address" id="setMyLocation" size="lg" autoComplete="off"
                                    placeholder={v.address.lat ? 'Enter new location' : 'Enter your address'}
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                        <div onClick={getMyCurrentLocation}>Use my current location</div>
                        {/* <div className="d-flex justify-content-center">
                            <div id="map" className="mt-4" />
                        </div>
                        <div className="small mt-3 text-muted text-center">
                            * You can either search your location, drag the marker <br /> or tap on one of the locations showing on the map.
                        </div> */}
                    </div>
                    {/* <div className="divider" /> */}

                    {/* ::: step 2 */}
                    <div className="divider" />
                    <div className={`animate__animated animate__fadeIn animate__faster mt-4 ${step !== 1 ? 'hide' : ''}`}>
                        <div className="fs-16 mb-3 fw-600">Choose a service option</div>
                        {options.map(option => (
                            <div key={option.key} className="mb-3">
                                <CustomRadio
                                    label={option.label} selected={v.option === option.key ? true : false}
                                    onClick={() => {
                                        _update.data('location', { ...v, option: option.key })
                                    }}
                                />
                            </div>
                        ))}

                        <p>&nbsp;</p>
                    </div>

                    {/*  footer */}
                    <div className={`${hasHeader ? 'footer' : ''}`}>
                        <Button variant="secondary btn-block btn-md" disabled={!(v.address.lat && v.address.lng && v.option)} onClick={submit}>
                            Continue
                        </Button>
                    </div>

                </div>
                <TabsOffset />
            </IonContent>
        </IonPage >
    );
}

export default Location;