import { func, types } from 'utils';

export const add = (item: types.ItemCart, items: types.ItemCart[], increment = '') => {
    let itam = { ...item };
    let itams = [...items];
    // check if item exists in cart
    let inCart: types.ItemCart = itams.find(c => c.uuid === itam.uuid) || ({} as types.ItemCart);
    if (inCart.uuid) {
        let newQty = 0;
        if (increment === '+') {
            newQty = increment ? (itam.quantity || 1) + 1 : 1;
            itam['quantity'] = newQty;
        }
        if (increment === '-') {
            newQty = increment ? (itam.quantity || 1) - 1 : 1;
            itam['quantity'] = newQty;
        }
        itam.price_total = total(itams, false) * newQty;
        itams[itams.indexOf(inCart)] = itam;
        return itams;
    } else {
        itam['quantity'] = 1;
        itams = itams.concat(itam);
        return itams;
    }
}

export const remove = (item: types.ItemCart, items: types.ItemCart[]) => {
    // check if item exists in cart
    let inCart = items.find(c => c.uuid === item.uuid) || {} as types.ItemCart;
    if (inCart.uuid) {
        items = items.filter(itm => itm.uuid !== item.uuid);
    }
    return items;
}

export const total = (items: any, addQty = true, subDiscount = false) => {
    let total = 0;
    let discount = 0;
    for (let i = 0; i < (items || []).length; i++) {
        let item = items[i];
        let price = +(item.price_discount || item.price);
        for (let v = 0; v < Object.keys(item.variants).length; v++) {
            let po = Object.keys(item.variants)[v] as any;
            for (let j = 0; j < (item.variants[po] || []).length; j++) {
                const iop = item.variants[po][j];
                if (iop.price > 0) {
                    price = price + (+iop.price * +iop.quantity);
                }
            }
        }
        if (addQty) {
            price = price * (item.quantity || 1);
        }
        total = total + price;
        discount = (discount || 0) + (item.discount || 0);
    }
    return total - (subDiscount ? discount : 0);
}

export const deliveryFee = (channel: string): number => {
    const location = func.getStorageJson('location') || {};
    const active_venue: types.Venue = func.getStorageJson('active_venue') || {};
    const delivery_partners = func.getStorageJson('delivery_partners') || [];

    const distance = +func.distanceMatrix(
        { lat: location?.address?.lat, lng: location?.address?.lng },
        { lat: active_venue?.address.lat, lng: active_venue?.address.lng, },
        true
    );

    if (channel) {
        switch (channel) {
            case 'cr':
                return active_venue?.delivery?.fee_type === 'flat' ? active_venue?.delivery?.fee : active_venue?.delivery?.fee * distance;

            default:
                const deliveryPartner = delivery_partners[channel] || {};
                const deliveryFee = (Object.keys(deliveryPartner[location.address.state.toLowerCase()]) || [])
                    .find((dist: string) => +dist.split('-')[0] <= distance && distance <= +dist.split('-')[1]);
                return deliveryFee ? deliveryPartner[location.address.state.toLowerCase()][deliveryFee] : 0;
        }
    }
    return 0;
}

export const totalItems = (items: any) => items.reduce((a: number, b: any) => a + b.quantity, 0) || 1;