import React from 'react';
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Filter, Header, ItemsSlider, Oops, TabsOffset } from 'components';
import { axius, func, types } from 'utils';
import { _store, _update } from '_Store';

let refresher: any = null;
interface Props extends RouteComponentProps, types.StateProps { };
const Home: React.FC<Props> = props => {

    const [step, setStep] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [chunks, setChunks] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [bigData, setBigData] = React.useState({});
    const [available, setAvailable] = React.useState(true);

    const { __data: { categories, active_venue, location, countries, home, promotions } } = _store.useState();

    React.useEffect(() => {
        setAvailable(
            countries.map((ol: types.Country) => { return ol.code.toLowerCase(); }).includes(location.address?.iso?.toLowerCase())
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, location.address?.iso]);

    React.useEffect(() => {
        if (available && active_venue) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available, location, active_venue]);

    const getData = () => {
        if (Object.keys(home).length > 0) {
            setLoading(false);
            let c = func.chunk(Object.keys(home), 30);
            setData(c[0] || []);
            setChunks(c);
            setBigData(home);
        } else {
            setLoading(true);
        }

        window.scroll(0, 0);
        if (active_venue.uuid) {
            axius.post('search/home', {
                city: location.address.city,
                range: 30,
                limit: 3,
                venues: [active_venue.uuid],
                latitude: location.address.lat,
                longitude: location.address.lng,
                delivery_options: [location.option],
                venue_categories: categories.map((ctg: types.Category) => ctg.uuid),
            }).then(res => {
                if (res.status === 200) {
                    const c = func.chunk(Object.keys(res.items), 30);
                    setData(c[0] || []);
                    setChunks(c);
                    setBigData(res.items);
                    _update.data('home', res.items);
                }
                refresher && refresher.detail.complete();
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }

    const getNext = () => {
        setStep(step + 1);
        setData(data.concat(chunks[step + 1]));
    }

    const hasMenuItems = !!(active_venue.uuid && categories.length > 0 && data.length > 0);
    const allItems: types.Item[] = Object.keys(bigData).map(ctg => bigData[ctg as keyof typeof bigData]).flat();
    const itemsWithPromo = allItems.filter(item => promotions.filter(promo => promo.venues.includes(active_venue.uuid) && (promo.items.includes(item.code) || promo.categories.includes(item.venue_categories[0].uuid))).length > 0);

    return (
        <IonPage id="Home">
            <Header {...props} showSearch={hasMenuItems} showSegments={false} />
            {/* backgroundImage: `url(assets/img/bg.png)`, */}
            <IonContent fullscreen className="bg-img" style={{ backgroundAttachment: 'fixed', height: '100%', }}>
                <IonRefresher slot="fixed" onIonRefresh={(e) => {
                    refresher = e;
                    getData();
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>

                <div className="">
                    {loading && categories.map((ctg) => {
                        let id = `${ctg.uuid}`;
                        return (
                            <div key={ctg.uuid}>
                                <ItemsSlider {...props} key={id} id={id} title={ctg.name} data={[]} loading={true} />
                            </div>
                        );
                    })}

                    {!loading && hasMenuItems ? (
                        <div>
                            <Filter {...props} />
                            {itemsWithPromo.length > 0 && (
                                <div>
                                    <ItemsSlider {...props} id="hot-deals" title="HOT DEALS" data={itemsWithPromo} loading={false} />
                                </div>
                            )}
                            {!loading && data.map((i: string) => {
                                let id = '';
                                let ctg = categories.find((c: types.Category) => c.uuid === i) as types.Category;
                                let smallData = ((bigData as any)[i] || []).map((row: types.Item) => {
                                    let venue = active_venue;
                                    return {
                                        ...row,
                                        distance: +func.distanceMatrix(
                                            { lat: location?.address?.lat, lng: location?.address?.lng },
                                            { lat: venue.address.lat, lng: venue.address.lng },
                                            true
                                        ),
                                    };
                                }).sort((a: any, b: any) => (a.distance > b.distance) ? 1 : -1);
                                id = `${ctg.uuid}`;
                                return (
                                    <div key={id}>
                                        <ItemsSlider {...props} key={id} id={id} title={ctg.name} data={smallData} loading={false} display={ctg.uuid ? 'item' : 'venue'} />
                                    </div>
                                );
                            })}

                            {!loading && (chunks.flat()).length > data.length && (
                                <div className="text-center pointer small mt-4" onClick={() => getNext()}>load more</div>
                            )}
                        </div>
                    ) : (
                        <Oops />
                    )}
                    <p>&nbsp;</p>
                </div>
                <TabsOffset />
            </IonContent>
        </IonPage>
    );
};

export default Home;
